// src/redux/codeSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { database } from "../../appWrite";

// Define a type for the property
interface Tenant {
  // Define the properties that a Tenant object should have
  id: string;
  image: string; // Array of image URLs
  firstname: string;
  lastname: string;
  address: string;
  role: string;
  relationship: string;
  property: string;
  paymentDate: string;
  expiryDate: string;
  amountPaid: string;
  outstanding: string;
  phone: string;
}

// Define the initial state type
interface AllTenantState {
  allTenants: Tenant[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

// Async thunk to fetch the properties from the server
export const fetchTenants = createAsyncThunk<Tenant[], void>(
  "tenants/fetchTenants",
  async () => {
    const response = await database.listDocuments(
      "67121ea200295b186e8d", // Your database ID
      "6796ffad0004675a3e33" // Your collection ID for tenants
    );

    // Map the documents to the Tenant type
    const allTenants = response.documents.map((doc) => ({
      id: doc.$id,
      image: doc.image, // Ensure that 'images' exists on your document model
      firstname: doc.firstname,
      lastname: doc.lastname,
      address: doc.address,
      relationship: doc.relationship,
      property: doc.property,
      paymentDate: doc.paymentDate,
      expiryDate: doc.expiryDate,
      amountPaid: doc.amountPaid,
      outstanding: doc.outstanding,
      role: doc.role,
      phone: doc.phone,
    }));

    return allTenants;
  }
);

const initialState: AllTenantState = {
  allTenants: [],
  status: "idle",
  error: null,
};

const allTenantSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenants.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchTenants.fulfilled,
        (state, action: PayloadAction<Tenant[]>) => {
          state.status = "succeeded";
          state.allTenants = action.payload; // Corrected this line
        }
      )
      .addCase(fetchTenants.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export default allTenantSlice.reducer;
