import React, { useState, ChangeEvent, FormEvent } from "react";
import {
  FaArrowLeft,
  FaEdit,
  FaSearch,
  FaTools,
  FaBell,
  FaTrash,
} from "react-icons/fa";
import useScrollToTop from "../../../components/useScrollToTop";
import { Link, useSearchParams } from "react-router-dom";
import BottomNavbar from "../../../components/BottomNavbar";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import { database } from "../../../appWrite";
import { useAppDispatch } from "../../../redux/hooks";
import { fetchProperties } from "../../../redux/slices/AllPropertySlice";
import { fetchTenants } from "../../../redux/slices/AllTenantSlice";

const AdminPropertyDetails: React.FC = () => {
  useScrollToTop();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [isLoading, setIsLoading] = useState(false);
  const State = useSelector((state: RootState) => state);
  const { allProperties: properties } = State.AllProperties;
  const { allTenants: tenants } = State.AllTenants;

  const currentProperty = properties.find((item) => item._id === id);
  const currentTenants = tenants.filter((tenant: any) =>
    currentProperty?.tenants.includes(tenant.id)
  );

  const [editingTenant, setEditingTenant] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    paymentDate: "",
    expiryDate: "",
    amountPaid: "",
    outstanding: "",
    phone: "",
  });

  const handleEditClick = (tenant: any) => {
    setEditingTenant(tenant.id);
    const { id, ...editableData } = tenant;
    setFormData(editableData);
  };

  const handleCloseModal = () => {
    setEditingTenant(null);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (!editingTenant) return;

    try {
      await database.updateDocument(
        "67121ea200295b186e8d",
        "6796ffad0004675a3e33",
        editingTenant,
        formData
      );
      setEditingTenant(null);
      dispatch(fetchProperties());
      dispatch(fetchTenants());
      setIsLoading(false);
    } catch (error) {
      console.error("Error updating tenant:", error);
    }
  };

  const handleDelete = async (tenantId: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this tenant?"
    );
    if (!confirmDelete) return;

    try {
      await database.deleteDocument(
        "67121ea200295b186e8d",
        "6796ffad0004675a3e33",
        tenantId
      );
      dispatch(fetchProperties());
      dispatch(fetchTenants());
      alert("Tenant deleted");
    } catch (error) {
      console.error("Error deleting tenant:", error);
    }
  };

  return (
    <div className="min-h-screen w-screen bg-gray-800 flex flex-col">
      <div className="lg:w-[74vw] lg:ml-[15vw] w-full h-full pt-4 lg:pt-6 px-4 sm:px-6">
        <div className="flex flex-wrap justify-between items-center gap-4">
          <h2 className="text-sm lg:text-2xl font-bold text-white">
            <Link to={"/admin-management"}>
              <button className="flex gap-2 items-center">
                <FaArrowLeft /> Back
              </button>
            </Link>
          </h2>

          <span className="flex flex-wrap gap-4 lg:gap-8 items-center w-full lg:w-auto justify-between lg:justify-end">
            <form id="search-form" className="relative w-full lg:w-auto">
              <FaSearch className="absolute top-2 left-3 text-gray-400" />
              <input
                type="text"
                placeholder="Find Resident"
                className="py-2 lg:py-3 w-full lg:w-auto pl-10 rounded-lg shadow-md shadow-gray-300"
              />
            </form>
            <span className="flex text-white gap-3 items-center">
              <FaTools className="cursor-pointer hover:text-blue-400" />
              <FaBell className="cursor-pointer hover:text-blue-400" />
            </span>
          </span>
        </div>

        <div className="bg-gray-50 py-5 lg:py-6 px-4 sm:px-6 w-full rounded-xl shadow mt-5 overflow-x-auto">
          <table className="w-full text-sm">
            <thead>
              <tr className="border-b border-gray-300">
                <th>Resident</th>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Amount Paid</th>
                <th>Outstanding</th>
                <th>Payment Date</th>
                <th>Expiry Date</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentTenants.map((user) => (
                <tr key={user.id} className="border-b border-gray-300 py-2">
                  <td>
                    <img
                      src={user.image}
                      alt=""
                      className="w-10 h-10 object-cover rounded-lg"
                    />
                  </td>
                  <td>{user.firstname}</td>
                  <td>{user.lastname}</td>
                  <td>{user.amountPaid}</td>
                  <td>{user.outstanding}</td>
                  <td>{user.paymentDate.split("T")[0]}</td>
                  <td>{user.expiryDate.split("T")[0]}</td>
                  <td>{user.phone}</td>
                  <td className="flex gap-3 justify-center py-2">
                    <FaEdit
                      onClick={() => handleEditClick(user)}
                      className="cursor-pointer hover:text-blue-600"
                    />
                    <FaTrash
                      onClick={() => handleDelete(user.id)}
                      className="text-red-600 cursor-pointer hover:text-red-700"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <BottomNavbar current={"dashboard"} />

      {editingTenant && (
        <div className="fixed inset-0 flex items-center justify-center overflow-y-scroll pt-32 pb-10 bg-black bg-opacity-50 px-4">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <button
              className="absolute top-2 right-2 text-xl font-bold"
              onClick={handleCloseModal}
            >
              &times;
            </button>
            <h3 className="text-lg font-semibold mb-4">Edit Tenant</h3>
            <form onSubmit={handleSubmit} className="space-y-3">
              <div className="flex flex-col">
                <label
                  htmlFor="firstname"
                  className="font-medium capitalize text-sm"
                >
                  First name
                </label>
                <input
                  type="text"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                  className="border-gray-500 border px-3 py-2 rounded-lg"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="lastname"
                  className="font-medium capitalize text-sm"
                >
                  Last name
                </label>
                <input
                  type="text"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                  className="border-gray-500 border px-3 py-2 rounded-lg"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="amountPaid"
                  className="font-medium capitalize text-sm"
                >
                  Amount Paid
                </label>
                <input
                  type="text"
                  name="amountPaid"
                  value={formData.amountPaid}
                  onChange={handleChange}
                  className="border-gray-500 border px-3 py-2 rounded-lg"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="outstanding"
                  className="font-medium capitalize text-sm"
                >
                  Outstanding
                </label>
                <input
                  type="text"
                  name="outstanding"
                  value={formData.outstanding}
                  onChange={handleChange}
                  className="border-gray-500 border px-3 py-2 rounded-lg"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="paymentDate"
                  className="font-medium capitalize text-sm"
                >
                  Payment Date
                </label>
                <input
                  type="date"
                  name="paymentDate"
                  value={formData.paymentDate}
                  onChange={handleChange}
                  className="border-gray-500 border px-3 py-2 rounded-lg"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="expiryDate"
                  className="font-medium capitalize text-sm"
                >
                  Expiry Date
                </label>
                <input
                  type="date"
                  name="expiryDate"
                  value={formData.expiryDate}
                  onChange={handleChange}
                  className="border-gray-500 border px-3 py-2 rounded-lg"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="phone"
                  className="font-medium capitalize text-sm"
                >
                  Phone
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="border-gray-500 border px-3 py-2 rounded-lg"
                />
              </div>
              <button
                type="submit"
                className={`bg-blue-500 text-white px-4 py-2 rounded-lg ${
                  isLoading && "animate-pulse"
                } font-medium mt-2 w-full`}
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Save"}
              </button>
              <button
                onClick={() => {
                  setEditingTenant(null);
                }}
                className="bg-red-500 text-white px-4 py-2 rounded-lg font-medium mt-2 w-full"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPropertyDetails;
